/**
 * Const with params element
 * Hero type2 component
 * Display the hero type 2: h1  + background-img
 */
/* Import section */
import React from "react"
import "../styles/components/herotype2.scss"

/* Declaration function */
const Herotype2 = ( { h1title, classImg } ) => {
  return (
    <div className={`wrap-hero-type2 ${classImg}`}>
      <div className="box-hero-type2">
        <h1 dangerouslySetInnerHTML={{__html:h1title}} />
      </div>
    </div>
  )
}

/* Export function */
export default Herotype2

