/**
 * 404 page
 */
/* Import section */
import * as React from "react"
import { graphql } from "gatsby"
import { useTranslation} from "gatsby-plugin-react-i18next"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {

  const {t} = useTranslation()
  var content = t('404-content')

  return (
    <Layout>
      <Seo title="404: Not found" />
      <section className="section-hero-general section-herotype2">
        <Herotype2 h1title={t('404-title')} />
      </section>
      <section>
        <div className="wrap-centercontent">
          <div className="centercontent general" dangerouslySetInnerHTML={{__html:content}} />
        </div>
      </section>
    </Layout>
  )

}

/* Query page */
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFoundPage
